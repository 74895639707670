type Props = {
  className?: string
}

const Desert = ({ className }: Props) => (
  <svg
    width="610"
    height="262"
    viewBox="0 0 610 262"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_503_320)">
      <path
        d="M216 61.0407C223.72 66.5258 303 154.973 345 192.04C387 229.108 292.644 210.969 253 208.973C210.661 206.841 152.5 190.5 189.5 169C226.5 147.5 208.281 55.5555 216 61.0407Z"
        fill="#D89B00"
      />
      <path
        d="M216 61.0408C223.719 66.5259 303 154.973 345 192.041C387 229.108 292.644 210.969 253 208.973C210.661 206.841 -30.0001 228.5 7 207C44.0001 185.5 208.281 55.5556 216 61.0408Z"
        fill="#FFB800"
      />
      <path
        d="M216 61.0407C223.719 66.5258 303 154.973 345 192.041C387 229.108 288.144 237.496 248.5 235.5C206.161 233.368 170 136.805 197.5 114C225 91.1953 208.281 55.5556 216 61.0407Z"
        fill="#D89B00"
      />
      <path
        d="M406 54.9997C415.028 53.3225 593.469 201.765 606 210.914C612.832 215.903 469.658 236.564 440.5 236C416.179 235.53 508.913 214.603 480 210.914C437.948 205.548 228.69 201.883 231 188C235.632 160.165 396.972 56.6769 406 54.9997Z"
        fill="#FFB800"
      />
      <path
        d="M406 54.9997C415.028 53.3225 593.469 201.765 606 210.914C612.832 215.903 469.657 236.564 440.5 236C416.179 235.53 508.912 214.603 480 210.914C437.948 205.548 364.5 166.414 406 130C447.5 93.5858 396.972 56.6769 406 54.9997Z"
        fill="#D89B00"
      />
      <path
        d="M273.126 101.021C281.95 99.3354 565.82 200.45 573.062 211.49C580.305 222.53 502.382 228.857 457.062 238.99C382.447 255.674 277.202 239.852 260.562 238.99C244.273 238.147 101.478 218.47 51.5 217C17.5 216 14.366 216.581 22.4999 210.5C92.7534 157.982 264.302 102.706 273.126 101.021Z"
        fill="#FFC700"
      />
      <path
        d="M273.126 101.021C286 101.021 576.258 201.46 583.5 212.5C590.742 223.54 502.383 228.856 457.062 238.99C382.447 255.674 260.562 238.99 260.562 238.99C307.5 234.5 260.251 101.021 273.126 101.021Z"
        fill="#DDAD01"
      />
      <g clipPath="url(#clip1_503_320)">
        <path
          d="M394.794 206.84C405.202 213.019 411.557 211.93 423.665 201.257C426.459 199.396 427.84 199.777 426.459 201.257C411.558 216.145 407.832 218.937 392 209.632C394.157 207.94 394.846 207.297 394.794 206.84Z"
          fill="#B38C00"
        />
        <path
          d="M361 217.669L377.311 206L393.366 206.974C394.172 207.143 395 210.853 395 211.827C395 212.8 394.792 216.457 394.056 216.695C393.319 216.933 390.445 217.981 389.333 217.669C388.222 217.357 385.013 217.366 384.394 218.658C383.252 221.04 379.427 220.598 374.477 221.578C374.477 221.578 372.333 222.537 371.389 221.563C370.444 220.59 369.5 219.616 368.556 219.616C367.611 219.616 367.611 220.59 366.667 220.59C365.722 220.59 363.833 220.59 363.833 220.59C363.833 220.59 368.556 215.721 367.611 215.721C366.667 215.721 364 216.464 361 217.669Z"
          fill="#B38C00"
        />
        <path
          d="M388 211.063C388 213.308 385.725 215 383.2 215C380.675 215 380 213.308 380 211.063C380 208.819 382.047 207 384.571 207C387.096 207 388 208.819 388 211.063Z"
          fill="#494242"
        />
        <path
          d="M371.109 171C366.597 179.685 371.97 191.165 385 199.933C380.37 197.867 379.09 199.67 379.444 202C366.213 193.071 363.7 179.267 371.109 171Z"
          fill="url(#paint0_linear_503_320)"
        />
        <path
          d="M394.794 204.664C405.202 212.491 411.557 211.111 423.665 197.592C426.459 195.235 427.84 195.718 426.459 197.592C411.558 216.451 407.832 219.987 392 208.2C394.157 206.058 394.846 205.243 394.794 204.664Z"
          fill="#D9D9D9"
        />
        <path
          d="M415 206.158C415 206.158 417.853 203.729 423.56 197.657C426.708 194.898 427.746 196.024 426.465 197.854C419.969 205.701 416.137 210.116 416.002 209.998L415 206.158Z"
          fill="url(#paint1_linear_503_320)"
        />
        <path
          d="M385.032 198.172C390.909 201.448 394.466 203.433 395 203.69C395 204.793 394.145 205.608 392.01 207L379.051 200.379C378.774 196.991 379.471 195.954 385.032 198.172Z"
          fill="url(#paint2_linear_503_320)"
        />
        <path
          d="M363.253 218.091C368.638 216.421 370.083 215.07 371.324 212.309H380.728C383.148 214.035 384.843 214.64 386.455 213.273C388.068 211.905 388.442 211.276 387.464 213.273C387.464 213.273 387.051 215.134 386.455 216.163C385.027 218.63 381.032 217.895 380.403 220.018C377.595 221.863 374.917 222.145 373.341 221.945C371.765 221.746 370.813 219.793 369.306 219.054C367.799 218.316 366.467 220.263 363.253 220.982C360.934 219.853 359.633 219.219 363.253 218.091Z"
          fill="#D9D9D9"
        />
        <path
          d="M368.543 211.03C371.713 208.419 373.008 203.66 374.157 203.179C375.307 202.698 377.854 201.539 379.771 202.198L391 207.105L386.321 209.067C386.555 209.342 384.634 208.401 382.579 209.067C380.523 209.734 380.336 213.023 381.405 214C382.474 214.977 372.594 212.941 371.81 213.2C371.025 213.459 367.579 216.4 366.643 216.4C365.707 216.4 369 211.081 368.543 212.012C368.209 212.691 364.014 216.877 361.992 217.9C361.243 218.279 359.533 217.5 360.121 216.919C360.708 216.338 366.617 213.261 368.543 211.03Z"
          fill="url(#paint3_linear_503_320)"
        />
        <path
          d="M380.631 208C377.181 209.343 389 206 389 206C387.778 206.836 386.054 213.466 384.351 215L383.421 214C389.33 210.185 384.081 206.657 380.631 208Z"
          fill="#D9D9D9"
        />
        <path
          d="M388.111 206C389.757 205.994 390.568 206.345 392 207C391.947 209.842 392 212 391.028 213C390.056 214 389.861 215 388.889 215C387.917 215 386.525 213.18 385 215C386.977 210.143 387.629 206.482 388.111 206Z"
          fill="#D9D9D9"
        />
        <path
          d="M387.302 206.99C389.131 206.153 391.129 205.26 391.985 206.99C392.078 208.688 391.693 210.033 391.857 211.355C392.021 212.678 390.638 214.131 389.905 214.848C389.172 215.565 388.135 213.441 386 214.848C388.785 209.929 387.603 208.738 387.302 206.99Z"
          fill="#9A9A9A"
        />
        <path
          d="M379.173 202C378.202 197.029 381.48 195.458 385 198.686C381.509 196.826 380.128 197.28 379.173 202Z"
          fill="#ABABAB"
        />
        <path
          d="M392.164 206.87C393.109 206.247 393.701 205.434 395 205C394.656 205.714 391.219 207.493 392.164 206.87Z"
          fill="#ABABAB"
        />
        <path
          d="M370.236 218.084C371.13 218.144 375.526 217.614 375.526 216.732C375.526 216.227 378.399 215.071 381.053 215C381.381 215.352 385 215.693 385 216.039C385 216.385 379.539 218.469 379.568 219.849C379.597 221.229 375.046 221.149 373.347 220.732C371.125 219.848 369.341 218.025 370.236 218.084Z"
          fill="#9A9A9A"
        />
        <path
          d="M365.968 212.468C365.968 212.468 367.447 211.416 366.864 212.467C366.28 213.519 361.578 218.33 360.596 217.982C359.614 217.634 360.014 217.591 360.596 216.879C362.266 215.719 363.61 215.265 365.968 212.468Z"
          fill="#9A9A9A"
        />
        <path
          d="M372 171C369.487 175.898 369.445 178.368 370.887 182.722L367.465 186C366.286 180.554 367.195 176.148 372 171Z"
          fill="url(#paint4_linear_503_320)"
        />
        <path
          d="M436 184.001L439 181.501C439.486 181.358 440.313 183.723 440 184.001L439 184.5C438.992 184.362 438.357 184.667 437.5 185.001C436.348 185.449 435.755 183.933 436 184.001Z"
          fill="#C49900"
        />
        <path
          d="M437.177 183.632C437.199 183.656 437.526 184.123 437.299 184.526L436.214 184.483C436.576 183.935 436.522 183.205 437.177 183.632Z"
          fill="#888888"
        />
        <path
          d="M440.193 182.794C439.904 182.776 439.344 183.575 439.608 183.858L437.299 184.527C437.33 184.129 437.265 183.984 437.176 183.632C437.71 182.595 439.634 182.029 440.193 182.794Z"
          fill="#7D7D7D"
        />
        <path
          d="M439.226 181.153L440.194 182.794C438.823 182.749 437.849 182.557 437.177 183.632L435.354 181.515C437.19 181.895 438.174 181.984 439.226 181.153Z"
          fill="#D9D9D9"
        />
        <path
          d="M435.354 181.515L437.177 183.632C436.828 183.827 436.48 184.022 436.214 184.483L434.768 182.578C435.15 182.415 435.266 182.16 435.354 181.515Z"
          fill="#C5C5C5"
        />
        <path
          d="M427.5 186L432 183.5C432.237 183.578 432.329 184.487 432.5 186C432.671 187.513 432 186.5 432 186.5C432.017 186.178 429.371 186.913 428.5 187C427.27 187.122 427.256 185.932 427.5 186Z"
          fill="#C49900"
        />
        <path
          d="M428.737 185.341C428.763 185.375 429.144 186.018 428.807 186.522L427.394 186.357C427.922 185.677 427.925 184.718 428.737 185.341Z"
          fill="#888888"
        />
        <path
          d="M432.76 184.55C432.384 184.497 431.574 185.484 431.889 185.88L428.807 186.522C428.888 186.007 428.818 185.811 428.737 185.342C429.537 184.04 432.106 183.494 432.76 184.55Z"
          fill="#7D7D7D"
        />
        <path
          d="M431.662 182.309L432.76 184.549C430.975 184.354 429.722 184.005 428.737 185.342L426.568 182.394C428.928 183.075 430.204 183.29 431.662 182.309Z"
          fill="#D9D9D9"
        />
        <path
          d="M426.568 182.394L428.737 185.341C428.262 185.561 427.788 185.781 427.394 186.357L425.697 183.725C426.212 183.55 426.389 183.228 426.568 182.394Z"
          fill="#C5C5C5"
        />
        <path
          d="M416.218 188.104L422.718 184.063C422.817 184.624 423.936 186.195 424 187C424.064 187.805 420.24 188.799 420 189C419.76 189.201 418.857 189.665 418 189.5C417.018 189.311 416.218 188.104 416.218 188.104Z"
          fill="#C49900"
        />
        <path
          d="M418.492 187.285C418.534 187.324 419.181 188.101 418.84 188.846L416.919 188.93C417.476 187.909 417.274 186.629 418.492 187.285Z"
          fill="#888888"
        />
        <path
          d="M423.69 185.361C423.177 185.372 422.308 186.865 422.814 187.324L418.84 188.846C418.837 188.14 418.701 187.894 418.492 187.285C419.279 185.377 422.59 184.094 423.69 185.361Z"
          fill="#7D7D7D"
        />
        <path
          d="M421.741 182.608L423.69 185.361C421.266 185.485 419.518 185.29 418.492 187.285L414.962 183.82C418.258 184.219 420.008 184.231 421.741 182.608Z"
          fill="#D9D9D9"
        />
        <path
          d="M414.963 183.82L418.492 187.285C417.906 187.681 417.32 188.077 416.919 188.93L414.087 185.783C414.736 185.439 414.903 184.972 414.963 183.82Z"
          fill="#C5C5C5"
        />
        <path
          d="M398.12 198.846L403.62 193.525C403.823 194.057 405.285 194.722 405.5 195.5C405.715 196.278 401.818 199.103 401.62 199.346C401.423 199.59 400.373 200 399.5 200C398.5 200 398.12 198.846 398.12 198.846Z"
          fill="#C49900"
        />
        <path
          d="M401.5 198C401.563 198.022 401.51 198.047 401.5 199L399.5 200C399.789 198.621 399.968 197.868 401.5 198Z"
          fill="#888888"
        />
        <path
          d="M405 193C404.459 193.255 404.244 195.252 405 195.5L401.5 199C401.22 196.35 402.868 192.028 405 193Z"
          fill="#7D7D7D"
        />
        <path
          d="M401.625 191L405 193C402.483 194.28 401.645 195.394 401.5 198L395 195.5C398.69 194.362 400.553 193.546 401.625 191Z"
          fill="#D9D9D9"
        />
        <path
          d="M395 195.5L401.5 198C400.462 198.143 400.006 198.539 399.5 200L395 198C395.526 197.327 395.483 196.751 395 195.5Z"
          fill="#C5C5C5"
        />
        <path
          d="M406.5 192.041L413 188C413.099 188.561 413.936 189.695 414 190.5C414.064 191.305 410.082 192.993 409.842 193.194C409.602 193.396 408.494 193.6 407.637 193.435C406.655 193.246 406.5 192.041 406.5 192.041Z"
          fill="#C49900"
        />
        <path
          d="M409 191.5C409.049 191.531 409.832 192.17 409.638 192.966L407.768 193.414C408.122 192.305 407.68 191.087 409 191.5Z"
          fill="#888888"
        />
        <path
          d="M413.738 188.623C413.236 188.731 412.667 190.361 413.251 190.716L409.638 192.966C409.501 192.274 409.321 192.058 409 191.5C409.41 189.476 412.417 187.588 413.738 188.623Z"
          fill="#7D7D7D"
        />
        <path
          d="M411.301 186.29L413.738 188.622C411.381 189.205 409.629 189.345 409 191.5L404.876 188.769C408.188 188.534 409.908 188.213 411.301 186.29Z"
          fill="#D9D9D9"
        />
        <path
          d="M404.876 188.769L409 191.5C408.5 192 408 192.5 407.769 193.414L404.39 190.863C404.961 190.401 405.037 189.911 404.876 188.769Z"
          fill="#C5C5C5"
        />
      </g>
    </g>
    <path
      d="M336.301 122.446C342.103 132.946 339.908 138.431 333.308 145.865C325.173 152.015 320.81 150.816 313.376 144.216C309.508 132.758 310.881 137.989 311.332 127.691C318.803 122.289 322.08 117.844 336.301 122.446Z"
      fill="url(#paint5_linear_503_320)"
      fillOpacity="0.15"
    />
    <path d="M310.584 127.028C316.316 127.259 318.912 126.67 323.58 125.193" stroke="#CDA94C" strokeLinecap="round" />
    <path d="M310.584 127.027C312.501 132.435 313.871 135.42 316.978 139.203" stroke="#939450" strokeLinecap="round" />
    <path d="M314.788 135.847C320.606 138.574 322.843 141.013 323.094 146.826" stroke="#939450" strokeLinecap="round" />
    <path d="M311.803 130.705C310.206 138.2 310.993 139.75 313.708 143.842" stroke="#939450" strokeLinecap="round" />
    <path d="M317.279 127.11C320.531 131.836 322.518 134.058 326.499 136.907" stroke="#939450" strokeLinecap="round" />
    <path d="M324.914 135.739C325.582 141.012 328.92 143.184 333.397 145.705" stroke="#939450" strokeLinecap="round" />
    <path d="M330.084 143.469C333.889 142.347 335.064 142.078 337.057 139.164" stroke="#939450" strokeLinecap="round" />
    <path d="M339.078 134.127C335.956 131.711 335.055 130.924 331.543 130.909" stroke="#939450" strokeLinecap="round" />
    <path d="M323.094 146.826C325.411 146.201 325.875 145.577 327.224 143.923" stroke="#939450" strokeLinecap="round" />
    <path d="M323.11 140.112C324.367 139.538 324.693 139.241 325.617 138.435" stroke="#939450" strokeLinecap="round" />
    <path d="M318.708 137.541C319.964 136.966 320.29 136.669 321.214 135.863" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M319.355 134.747C320.789 133.133 318.31 131.881 318.31 131.881" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M316.772 129.283C314.983 129.738 314.688 127.083 314.688 127.083" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M315.444 130.778C313.655 131.234 312.247 131.179 312.247 131.179" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M316.529 138.594C314.74 139.049 313.332 138.994 313.332 138.994" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M316.325 146.166C315.536 144.796 315.742 142.305 315.742 142.305" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M322.182 149.36C321.102 149.07 319.149 145.999 319.149 145.999" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M312.808 136.572C312.186 136.576 311.129 136.203 311.129 136.203" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M321.955 137.567C321.231 137.665 319.684 137.112 319.684 137.112" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M328.593 130.843C327.869 130.941 326.322 130.388 326.322 130.388" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M318.681 126.192C317.976 125.48 318.597 124.781 318.597 124.781" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M322.248 120.667C323.244 119.546 325.318 119.075 325.318 119.075" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M326.239 128.222C325.491 127.558 326.109 126.439 326.109 126.439" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M330.104 126.266C329.819 125.308 330.934 124.683 330.934 124.683" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M319.648 141.055C319.364 140.096 320.479 139.471 320.479 139.471" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M312.133 140.92C312.654 140.067 313.865 140.476 313.865 140.476" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M320.185 145.529C319.527 144.776 320.281 143.743 320.281 143.743" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M328.818 135.861C327.364 135.239 326.616 134.575 326.616 134.575" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M335.087 134.072C334.187 133.014 332.255 132.742 332.255 132.742" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M331.019 137.147C329.423 137.625 328.687 137.986 327.574 138.768" stroke="#A5873A" strokeLinecap="round" />
    <path d="M323.789 128.722C322.101 127.97 320.384 126.533 320.384 126.533" stroke="#A5873A" strokeLinecap="round" />
    <path
      d="M323.915 146.654C323.915 146.654 326.23 148.33 327.154 147.524M322.094 141.927C322.094 141.927 324.41 143.603 325.334 142.797"
      stroke="#A5873A"
      strokeLinecap="round"
    />
    <path d="M312.918 142.472C315.277 142.019 316.617 141.736 318.552 142.643" stroke="#A5873A" strokeLinecap="round" />
    <path d="M324.167 135.075C326.484 134.449 328.027 134.116 329.376 132.461" stroke="#A5873A" strokeLinecap="round" />
    <path
      d="M325.742 131.794C326.193 130.087 328.73 128.429 328.73 128.429M322.13 130.592C323.957 131.546 326.876 129.989 326.876 129.989"
      stroke="#A5873A"
      strokeLinecap="round"
    />
    <path d="M315.262 132.567C317.243 132.308 318.577 132.224 319.505 130.702" stroke="#A5873A" strokeLinecap="round" />
    <path d="M321.094 124.325C323.075 124.066 322.248 120.668 322.248 120.668" stroke="#A5873A" strokeLinecap="round" />
    <path d="M326.651 123.239C325.939 121.99 326.152 120.79 326.152 120.79" stroke="#A5873A" strokeLinecap="round" />
    <path d="M328.975 120.622C327.521 120 326.152 120.79 326.152 120.79" stroke="#A5873A" strokeLinecap="round" />
    <path d="M330.97 124.4C331.918 122.998 332.702 122.453 335.827 122.694" stroke="#A5873A" strokeLinecap="round" />
    <path
      d="M327.441 124.609C329.422 124.35 330.096 121.618 330.096 121.618M336.994 130.417C336.337 129.114 336.755 127.431 337.864 127.177M330.848 128.302C331.394 126.729 332.227 126.066 333.835 124.937M334.793 129.131C334.136 127.828 332.508 126.433 332.508 126.433"
      stroke="#A5873A"
      strokeLinecap="round"
    />
    <path d="M317.93 145.865C319.911 145.606 321.667 145.898 322.594 144.377" stroke="#A5873A" strokeLinecap="round" />
    <path d="M330.918 143.075C330.943 141.408 330.972 140.908 330.335 139.572" stroke="#A5873A" strokeLinecap="round" />
    <path d="M329.758 147.394C330.453 146.013 330.967 144.174 330.359 143.915" stroke="#A5873A" strokeLinecap="round" />
    <path d="M334.525 141.155C332.944 138.647 332.498 137.882 330.399 136.295" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M336.17 140.383C336.418 138.597 336.255 138.128 335.297 136.999" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M337.084 136.514C337.494 134.898 337.742 134.702 337.431 133.255" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M332.767 142.71C334.406 143.012 335.351 143.447 336.775 143.042" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M323.58 125.193C329.282 127.612 331.362 129.921 332.679 135.277" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M323.58 125.193C329.721 124.384 334.385 123.562 338.238 127.509" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M312.788 126.978C317.674 123.17 320.83 119.338 326.151 120.79" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M164 141L163 137.5L229 124V129L164 141Z" fill="#563629" />
    <path d="M164 158V153L229 141V146L164 158Z" fill="#563629" />
    <path d="M140.5 169L135.5 167L160 138.5L161 142L140.5 169Z" fill="#563629" />
    <path d="M233 128.5L232 125H301V130L233 128.5Z" fill="#563629" />
    <path d="M360.5 118C356 118 355 114 360.5 114L399 116.5V119.5L360.5 118Z" fill="#563629" />
    <path d="M401.5 119.5C397 119.5 396.5 117 402 117L451 139.5V143L401.5 119.5Z" fill="#563629" />
    <path d="M401.5 131.5C397 131.5 397 128 402.5 128L451.5 150V153.5L401.5 131.5Z" fill="#563629" />
    <path d="M360.5 133V128.25H398V132L360.5 133Z" fill="#563629" />
    <path d="M234 145.25V141L267 154.6L266.1 158L234 145.25Z" fill="#563629" />
    <path d="M227.5 113H232.5L235 157.5L229 158L227.5 113Z" fill="#563629" />
    <path d="M300 110L305 111.5V155H298L300 110Z" fill="#563629" />
    <path d="M355 102.5L360 101.5L361 146H356L355 102.5Z" fill="#563629" />
    <path d="M399 110.5H402.5L402 142.758L397 141L399 110.5Z" fill="#563629" />
    <path d="M451 135H455.5V161.5L450 159.5L451 135Z" fill="#563629" />
    <path d="M159.5 125L164 124V169H158L159.5 125Z" fill="#563629" />
    <path d="M305 133V128L356 114V118.5L305 133Z" fill="#563629" />
    <g clipPath="url(#clip2_503_320)">
      <g clipPath="url(#clip3_503_320)">
        <path
          opacity="0.3"
          d="M206.5 184C209.5 181.5 213.488 183.008 210.5 186L205 192L199 190L206.5 184Z"
          fill="black"
        />
        <path opacity="0.3" d="M202 173C208.5 167 214.5 170 211 174L182 203L171 203L202 173Z" fill="black" />
        <path opacity="0.3" d="M205 192C204 193.5 202 194.5 198.5 193.5L193 192L196 189L205 192Z" fill="black" />
      </g>
      <path d="M171 128.322C171 110.976 192 110.147 192 128.322V203H171V128.322Z" fill="#217F00" />
      <path d="M197 140.659C197 131.78 209 131.78 209 140.659V165H197V140.659Z" fill="#217F00" />
      <path d="M209 165C208.957 172.245 206.608 174.985 199.609 174.941L188 175V165H209Z" fill="#217F00" />
      <path
        opacity="0.2"
        d="M173.5 123L175 123L173.5 126.299L175 129.598L173.5 132.897L175 136.196L173.5 139.495L175 142.794L173.5 146.093L175 149.392L173.5 152.691L175 155.99L173.5 159.289L175 162.588L173.5 165.887L175 169.186L173.5 172.485L175 175.784L173.5 179.082L175 182.381L173.5 185.68L175 188.979L173.5 192.278L175 195.577L173.5 198.876L175 202.175L174.25 203H172.75L173.5 202.175L172 198.876L173.5 195.577L172 192.278L173.5 188.979L172 185.68L173.5 182.381L172 179.082L173.5 175.784L172 172.485L173.5 169.186L172 165.887L173.5 162.588L172 159.289L173.5 155.99L172 152.691L173.5 149.392L172 146.093L173.5 142.794L172 139.495L173.5 136.196L172 132.897L173.5 129.598L172 126.299L173.5 123Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M189 123H190.5L189.5 126.299L190.5 129.5L189.5 132.897L190.5 136L189.5 139.5L190.5 143L189.5 146.093L190.5 149.5L189.5 152.691L190.5 156L189.5 159.289L190.5 162.5L189.5 166L190.5 169L189.5 172.484L190.5 175.783L189.5 179.082L190.5 182L189.4 185.68L190.5 188.979L189.4 192.278L190.5 195.5L189.4 198.876L190.5 202L190 203H188.5L189 202L188 198.876L189 195.5L188 192.278L189 188.979L188 185.68L189 182L188 179.082L189 175.783L188 172.5L189 169.185L188 165.886L189 162.5L188 159.289L189 156L188 152.691L189 149.392L188 146.093L189 143L188 139.495L189 136L188 132.897L189 129.5L188 126.299L189 123Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M175 120H176.5L178 123.287L176.5 126.574L178 129.861L176.5 133.149L178 136.436L176.5 139.723L178 143.01L176.5 146.297L178 149.584L176.5 152.871L178 156.158L176.5 159.446L178 162.733L176.5 166.02L178 169.307L176.5 172.594L178 175.881L176.5 179.168L178 182.455L176.5 185.743L178 189.03L176.5 192.317L178 195.604L176.5 198.891L178 202.178L177.25 203H175.75L176.5 202.178L175 198.891L176.5 195.604L175 192.317L176.5 189.03L175 185.743L176.5 182.455L175 179.168L176.5 175.881L175 172.594L176.5 169.307L175 166.02L176.5 162.733L175 159.446L176.5 156.158L175 152.871L176.5 149.584L175 146.297L176.5 143.01L175 139.723L176.5 136.436L175 133.149L176.5 129.861L175 126.574L176.5 123.287L175 120Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M185 120H186.5L188 123.287L186.5 126.574L188 129.861L186.5 133.149L188 136.436L186.5 139.723L188 143.01L186.5 146.297L188 149.584L186.5 152.871L188 156.158L186.5 159.446L188 162.733L186.5 166.02L188 169.307L186.5 172.594L188 175.881L186.5 179.168L188 182.455L186.5 185.743L188 189.03L186.5 192.317L188 195.604L186.5 198.891L188 202.178L187.25 203H185.75L186.5 202.178L185 198.891L186.5 195.604L185 192.317L186.5 189.03L185 185.743L186.5 182.455L185 179.168L186.5 175.881L185 172.594L186.5 169.307L185 166.02L186.5 162.733L185 159.446L186.5 156.158L185 152.871L186.5 149.584L185 146.297L186.5 143.01L185 139.723L186.5 136.436L185 133.149L186.5 129.861L185 126.574L186.5 123.287L185 120Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M180 117H182L180 119.481L182 122.788L180 126.096L182 129.404L180 132.712L182 136.019L180 139.327L182 142.635L180 145.942L182 149.25L180 152.558L182 155.865L180 159.173L182 162.481L180 165.788L182 169.096L180 172.404L182 175.712L180 179.019L182 182.327L180 185.635L182 188.942L180 192.25L182 195.558L180 198.865L182 202.173L181 203H179L180 202.173L178 198.865L180 195.558L178 192.25L180 188.942L178 185.635L180 182.327L178 179.019L180 175.712L178 172.404L180 169.096L178 165.788L180 162.481L178 159.173L180 155.865L178 152.558L180 149.25L178 145.942L180 142.635L178 139.327L180 136.019L178 132.712L180 129.404L178 126.096L180 122.788L178 119.481L180 117Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M183.5 117H185L183.5 119.481L185 122.788L183.5 126.096L185 129.404L183.5 132.712L185 136.019L183.5 139.327L185 142.635L183.5 145.942L185 149.25L183.5 152.558L185 155.865L183.5 159.173L185 162.481L183.5 165.788L185 169.096L183.5 172.404L185 175.712L183.5 179.019L185 182.327L183.5 185.635L185 188.942L183.5 192.25L185 195.558L183.5 198.865L185 202.173L184.25 203H182.75L183.5 202.173L182 198.865L183.5 195.558L182 192.25L183.5 188.942L182 185.635L183.5 182.327L182 179.019L183.5 175.712L182 172.404L183.5 169.096L182 165.788L183.5 162.481L182 159.173L183.5 155.865L182 152.558L183.5 149.25L182 145.942L183.5 142.635L182 139.327L183.5 136.019L182 132.712L183.5 129.404L182 126.096L183.5 122.788L182 119.481L183.5 117Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M191 166H192.5L194 169.2L192.5 172.4L193.25 174H191.75L191 172.4L192.5 169.2L191 166Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M194 166H195.5L197 169.2L195.5 172.4L196.25 174H194.75L194 172.4L195.5 169.2L194 166Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M200 136H202L200 139.196L202 142.511L200 145.825L202 149.14L200 152.455L202 155.769L200 159.084L202 162.399L200 165.713L202 169.028L200 172.343L201 174H199L198 172.343L200 169.028L198 165.713L200 162.399L198 159.084L200 155.769L198 152.455L200 149.14L198 145.825L200 142.511L198 139.196L200 136Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M203.5 136H205L203.5 139.304L205 142.609L203.5 145.913L205 149.217L203.5 152.522L205 155.826L203.5 159.13L205 162.435L203.5 165.739L205 169.043L203.5 172.348L204.25 174H202.75L202 172.348L203.5 169.043L202 165.739L203.5 162.435L202 159.13L203.5 155.826L202 152.522L203.5 149.217L202 145.913L203.5 142.609L202 139.304L203.5 136Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M205.75 138H207.25L206.5 139.659L208 142.976L206.5 146.293L208 149.61L206.5 152.927L208 156.244L206.5 159.561L208 162.878L206.5 166.195L208 169.512L207.25 172H205.75L206.5 169.512L205 166.195L206.5 162.878L205 159.561L206.5 156.244L205 152.927L206.5 149.61L205 146.293L206.5 142.976L205 139.659L205.75 138Z"
        fill="black"
      />
      <line x1="171.776" y1="134.447" x2="169.988" y2="133.553" stroke="black" strokeOpacity="0.5" />
      <line x1="175.976" y1="146.732" x2="174.018" y2="147.141" stroke="black" strokeOpacity="0.5" />
      <line x1="193.06" y1="155.489" x2="191.102" y2="155.898" stroke="black" strokeOpacity="0.5" />
      <line x1="185.356" y1="138.257" x2="183.911" y2="136.875" stroke="black" strokeOpacity="0.5" />
      <line x1="178.1" y1="116.744" x2="176.654" y2="115.361" stroke="black" strokeOpacity="0.5" />
      <line x1="190.054" y1="118.473" x2="188.161" y2="119.117" stroke="black" strokeOpacity="0.5" />
      <line x1="172.054" y1="181.473" x2="170.161" y2="182.117" stroke="black" strokeOpacity="0.5" />
      <line x1="193.054" y1="193.473" x2="191.161" y2="194.117" stroke="black" strokeOpacity="0.5" />
      <line x1="184.551" y1="186.381" x2="184.337" y2="188.37" stroke="black" strokeOpacity="0.5" />
      <line x1="177.825" y1="167.912" x2="179.142" y2="169.418" stroke="black" strokeOpacity="0.5" />
      <line x1="193.376" y1="163.671" x2="194.693" y2="165.176" stroke="black" strokeOpacity="0.5" />
      <line x1="207.819" y1="150.256" x2="209.684" y2="149.534" stroke="black" strokeOpacity="0.5" />
      <line x1="201.206" y1="140.211" x2="201.689" y2="138.27" stroke="black" strokeOpacity="0.5" />
      <line x1="202.067" y1="158.075" x2="201.463" y2="156.168" stroke="black" strokeOpacity="0.5" />
      <line x1="197.446" y1="175.673" x2="198.239" y2="173.837" stroke="black" strokeOpacity="0.5" />
      <line x1="203.888" y1="169.895" x2="205.761" y2="169.193" stroke="black" strokeOpacity="0.5" />
      <line x1="175.825" y1="198.233" x2="177.698" y2="197.532" stroke="black" strokeOpacity="0.5" />
      <line x1="185.011" y1="163.978" x2="185.99" y2="162.234" stroke="black" strokeOpacity="0.5" />
      <line x1="191.382" y1="131.022" x2="193.034" y2="129.895" stroke="black" strokeOpacity="0.5" />
      <line x1="196.718" y1="143.714" x2="198.371" y2="142.587" stroke="black" strokeOpacity="0.5" />
    </g>
    <path
      d="M509.345 190.918C513.483 179.657 519.066 177.726 529.006 177.847C538.981 179.969 540.931 184.052 540.809 193.993C534.681 204.419 537.736 199.956 529.657 206.357C520.682 204.247 515.177 204.68 509.345 190.918Z"
      fill="url(#paint6_linear_503_320)"
      fillOpacity="0.15"
    />
    <path d="M529.645 207.357C526.067 202.872 523.922 201.296 519.749 198.735" stroke="#CDA94C" strokeLinecap="round" />
    <path d="M529.645 207.357C532.477 202.368 533.837 199.378 534.662 194.553" stroke="#939450" strokeLinecap="round" />
    <path d="M533.559 198.405C531.811 192.222 532.19 188.935 536.419 184.939" stroke="#939450" strokeLinecap="round" />
    <path d="M531.627 204.028C538.337 200.328 538.993 198.718 540.309 193.987" stroke="#939450" strokeLinecap="round" />
    <path d="M525.324 202.243C526.767 196.69 527.145 193.734 526.692 188.859" stroke="#939450" strokeLinecap="round" />
    <path d="M526.847 190.822C530.396 186.865 529.852 182.919 528.826 177.884" stroke="#939450" strokeLinecap="round" />
    <path d="M529.306 181.853C525.966 179.711 524.993 179 521.486 179.401" stroke="#939450" strokeLinecap="round" />
    <path d="M516.355 181.171C516.573 185.112 516.569 186.309 518.856 188.973" stroke="#939450" strokeLinecap="round" />
    <path d="M536.419 184.939C534.43 183.597 533.655 183.654 531.521 183.718" stroke="#939450" strokeLinecap="round" />
    <path d="M531.334 189.322C530.077 188.748 529.639 188.696 528.425 188.526" stroke="#939450" strokeLinecap="round" />
    <path d="M532.273 194.334C531.016 193.76 530.578 193.708 529.364 193.537" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M529.737 195.673C527.578 195.646 528.255 198.34 528.255 198.34" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M527.298 201.203C528.813 202.257 527 204.218 527 204.218" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M529.298 201.228C530.813 202.282 531.694 203.382 531.694 203.382" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M534.495 195.291C536.01 196.345 536.89 197.445 536.89 197.445" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M540.352 190.487C539.834 191.981 537.816 193.456 537.816 193.456" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M538.932 183.969C539.42 184.975 538.377 188.462 538.377 188.462" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M535.404 199.427C535.813 199.895 536.224 200.937 536.224 200.937" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M530.167 191.862C530.715 192.345 531.31 193.877 531.31 193.877" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M520.738 191.247C521.286 191.73 521.881 193.261 521.881 193.261" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M523.712 201.784C523.635 202.783 522.7 202.772 522.7 202.772" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M517.201 202.704C515.701 202.686 513.988 201.427 513.988 201.427" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M520.298 194.742C520.286 195.742 519.035 196.008 519.035 196.008" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M516.29 193.101C515.752 193.944 514.549 193.51 514.549 193.51" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M534.313 191.322C533.775 192.165 532.572 191.73 532.572 191.73" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M539.132 197.091C538.146 197.256 537.662 196.072 537.662 196.072" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M537.344 187.987C537.205 188.977 535.931 189.083 535.931 189.083" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M524.384 187.792C524.865 189.298 524.853 190.297 524.853 190.297" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M518.927 184.224C518.716 185.596 519.776 187.235 519.776 187.235" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M523.914 185.285C525.32 186.179 526.075 186.499 527.396 186.828" stroke="#A5873A" strokeLinecap="round" />
    <path d="M522.28 196.266C522.816 198.035 522.855 200.274 522.855 200.274" stroke="#A5873A" strokeLinecap="round" />
    <path
      d="M535.752 184.431C535.752 184.431 535.503 181.584 534.289 181.413M533.371 188.902C533.371 188.902 533.122 186.054 531.908 185.883"
      stroke="#A5873A"
      strokeLinecap="round"
    />
    <path d="M539.791 195.48C537.903 193.994 536.812 193.167 536.231 191.111" stroke="#A5873A" strokeLinecap="round" />
    <path d="M526.835 191.822C524.845 190.479 523.582 189.531 521.448 189.595" stroke="#A5873A" strokeLinecap="round" />
    <path
      d="M523.323 192.779C521.738 193.556 518.823 192.724 518.823 192.724M524.78 196.297C524.304 194.291 521.217 193.104 521.217 193.104"
      stroke="#A5873A"
      strokeLinecap="round"
    />
    <path d="M530.769 200.194C529.277 198.866 528.339 197.913 526.582 198.208" stroke="#A5873A" strokeLinecap="round" />
    <path d="M520.721 201.182C519.228 199.854 517.201 202.705 517.201 202.705" stroke="#A5873A" strokeLinecap="round" />
    <path d="M516.262 197.693C515.784 199.049 514.737 199.674 514.737 199.674" stroke="#A5873A" strokeLinecap="round" />
    <path d="M512.762 197.65C513.244 199.156 514.738 199.674 514.738 199.674" stroke="#A5873A" strokeLinecap="round" />
    <path d="M514.311 193.668C512.631 193.87 511.706 193.634 509.842 191.114" stroke="#A5873A" strokeLinecap="round" />
    <path
      d="M516.78 196.199C515.288 194.871 512.781 196.15 512.781 196.15M514.915 185.175C514.361 186.525 512.816 187.311 511.897 186.638M517.341 191.206C515.794 191.823 514.748 191.627 512.842 191.15M515.384 187.681C514.83 189.031 514.842 191.175 514.842 191.175"
      stroke="#A5873A"
      strokeLinecap="round"
    />
    <path d="M539.074 189.471C537.581 188.143 536.652 186.625 534.895 186.92" stroke="#A5873A" strokeLinecap="round" />
    <path d="M528.461 181.481C527.185 182.553 526.789 182.858 526.195 184.215" stroke="#A5873A" strokeLinecap="round" />
    <path d="M532.486 179.53C530.987 179.909 529.26 180.724 529.463 181.353" stroke="#A5873A" strokeLinecap="round" />
    <path d="M524.649 180.011C523.788 182.848 523.502 183.686 523.677 186.311" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M522.988 179.273C521.476 180.255 521.228 180.685 521.002 182.148" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M519.465 181.115C517.976 181.864 517.665 181.804 516.775 182.987" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M526.975 180.323C526.131 178.885 525.84 177.887 524.601 177.075" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M519.75 198.736C517.845 192.841 518.228 189.758 521.415 185.255" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M519.75 198.735C515.117 194.623 511.442 191.635 511.903 186.138" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M528.165 205.724C522.087 204.524 517.124 204.647 514.738 199.674" stroke="#C0AF69" strokeLinecap="round" />
    <path
      d="M102.757 184.243C114.725 183.418 118.77 187.724 122.721 196.846C124.86 206.817 121.931 210.265 112.808 214.217C100.789 212.884 106.11 213.848 96.9661 209.091C95.2243 200.037 92.5794 195.189 102.757 184.243Z"
      fill="url(#paint7_linear_503_320)"
      fillOpacity="0.15"
    />
    <path d="M96.0489 209.488C98.6798 204.39 99.2417 201.788 99.874 196.934" stroke="#CDA94C" strokeLinecap="round" />
    <path d="M96.0486 209.488C101.759 210.034 105.044 210.054 109.785 208.835" stroke="#939450" strokeLinecap="round" />
    <path d="M105.819 209.402C110.748 205.28 113.903 204.283 119.279 206.51" stroke="#939450" strokeLinecap="round" />
    <path d="M99.8975 209.937C106.016 214.549 107.754 214.49 112.61 213.758" stroke="#939450" strokeLinecap="round" />
    <path d="M98.9507 203.455C104.608 202.503 107.461 201.64 111.725 199.234" stroke="#939450" strokeLinecap="round" />
    <path d="M109.997 200.178C115.059 201.8 118.437 199.691 122.614 196.697" stroke="#939450" strokeLinecap="round" />
    <path d="M119.188 198.756C119.777 194.834 120.029 193.655 118.229 190.618" stroke="#939450" strokeLinecap="round" />
    <path d="M114.517 186.658C111.01 188.468 109.915 188.953 108.419 192.129" stroke="#939450" strokeLinecap="round" />
    <path d="M119.278 206.51C119.69 204.146 119.321 203.462 118.391 201.541" stroke="#939450" strokeLinecap="round" />
    <path d="M113.2 203.66C113.21 202.279 113.078 201.858 112.738 200.68" stroke="#939450" strokeLinecap="round" />
    <path d="M109.009 206.565C109.019 205.183 108.887 204.763 108.547 203.585" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M106.751 204.798C105.893 202.817 103.711 204.535 103.711 204.535" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M100.706 204.832C100.363 206.645 97.8325 205.792 97.8325 205.792" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M101.501 206.667C101.159 208.481 100.514 209.734 100.514 209.734" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M109.044 208.984C108.701 210.798 108.056 212.051 108.056 212.051" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M115.821 212.367C114.246 212.504 112.075 211.265 112.075 211.265" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M121.19 208.407C120.471 209.263 116.862 209.737 116.862 209.737" stroke="#BEAA68" strokeLinecap="round" />
    <path d="M105.64 211.504C105.38 212.069 104.597 212.869 104.597 212.869" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M110.404 203.633C110.187 204.33 109.033 205.499 109.033 205.499" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M107.113 194.776C106.896 195.473 105.741 196.642 105.741 196.642" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M98.7111 201.797C97.7676 202.134 97.396 201.276 97.396 201.276" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M95.2098 196.229C94.6136 194.853 95.063 192.775 95.063 192.775" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M103.743 195.803C102.825 196.2 102.072 195.167 102.072 195.167" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M103.602 191.474C102.613 191.327 102.518 190.052 102.518 190.052" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M112.592 207.196C111.603 207.049 111.507 205.774 111.507 205.774" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M109.296 213.952C108.742 213.119 109.625 212.194 109.625 212.194" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M116.874 208.599C115.913 208.878 115.296 207.758 115.296 207.758" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M111.756 196.691C110.578 197.746 109.661 198.144 109.661 198.144" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M112.782 190.253C111.443 190.621 110.381 192.258 110.381 192.258" stroke="#C79C2C" strokeLinecap="round" />
    <path d="M113.852 195.239C113.611 196.887 113.627 197.707 113.867 199.046" stroke="#A5873A" strokeLinecap="round" />
    <path d="M103.162 198.234C101.767 199.447 99.7391 200.397 99.7391 200.397" stroke="#A5873A" strokeLinecap="round" />
    <path
      d="M119.469 205.693C119.469 205.693 121.966 204.302 121.626 203.125M114.416 205.347C114.416 205.347 116.912 203.956 116.572 202.778"
      stroke="#A5873A"
      strokeLinecap="round"
    />
    <path d="M111.035 213.895C111.62 211.565 111.929 210.231 113.568 208.861" stroke="#A5873A" strokeLinecap="round" />
    <path d="M109.079 200.575C109.491 198.21 109.84 196.67 108.91 194.749" stroke="#A5873A" strokeLinecap="round" />
    <path
      d="M106.771 197.761C105.413 196.632 104.982 193.632 104.982 193.632M104.155 200.528C105.792 199.274 105.614 195.971 105.614 195.971"
      stroke="#A5873A"
      strokeLinecap="round"
    />
    <path d="M103.046 207.587C103.648 205.682 104.135 204.437 103.147 202.954" stroke="#A5873A" strokeLinecap="round" />
    <path d="M98.0378 198.82C98.6398 196.915 95.2098 196.23 95.2098 196.23" stroke="#A5873A" strokeLinecap="round" />
    <path d="M99.4001 193.325C97.967 193.442 96.9688 192.743 96.9688 192.743" stroke="#A5873A" strokeLinecap="round" />
    <path d="M98.0092 190.113C96.8317 191.168 96.969 192.743 96.969 192.743" stroke="#A5873A" strokeLinecap="round" />
    <path d="M102.276 189.899C101.406 188.449 101.243 187.508 102.781 184.777" stroke="#A5873A" strokeLinecap="round" />
    <path
      d="M100.976 193.187C101.578 191.282 99.3857 189.517 99.3857 189.517M110.274 186.98C108.817 187.026 107.467 185.937 107.706 184.823M105.762 191.659C104.567 190.499 104.318 189.464 103.974 187.529M108.179 188.432C106.721 188.478 104.769 189.365 104.769 189.365"
      stroke="#A5873A"
      strokeLinecap="round"
    />
    <path d="M116.226 210.785C116.828 208.88 117.834 207.412 116.847 205.929" stroke="#A5873A" strokeLinecap="round" />
    <path d="M119.182 197.834C117.682 197.107 117.241 196.87 115.761 196.883" stroke="#A5873A" strokeLinecap="round" />
    <path d="M122.607 200.709C121.649 199.496 120.199 198.254 119.708 198.696" stroke="#A5873A" strokeLinecap="round" />
    <path d="M118.965 193.754C116.025 194.127 115.143 194.208 112.818 195.441" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M118.96 191.936C117.446 190.957 116.952 190.907 115.524 191.299" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M115.839 189.474C114.547 188.42 114.475 188.113 113.031 187.783" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M119.632 196.004C120.599 194.646 121.391 193.973 121.626 192.511" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M99.8738 196.934C104.475 192.786 107.446 191.877 112.858 192.945" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M99.8738 196.934C101.734 191.025 102.959 186.45 108.164 184.625" stroke="#C0AF69" strokeLinecap="round" />
    <path d="M96.9346 207.47C95.5463 201.433 93.4054 196.953 96.9689 192.743" stroke="#C0AF69" strokeLinecap="round" />
    <defs>
      <linearGradient
        id="paint0_linear_503_320"
        x1="365.552"
        y1="110.033"
        x2="378.51"
        y2="201.645"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A09B9B" />
        <stop offset="1" stopColor="#D9D9D9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_503_320"
        x1="431.17"
        y1="192.799"
        x2="409.071"
        y2="205.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7C7C7C" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_503_320"
        x1="411.946"
        y1="173.896"
        x2="379.24"
        y2="201.471"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#969696" />
        <stop offset="1" stopColor="#D9D9D9" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_503_320"
        x1="386.32"
        y1="200.235"
        x2="359.233"
        y2="219.605"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4D4D4" />
        <stop offset="1" stopColor="#D9D9D9" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_503_320"
        x1="368.577"
        y1="175.344"
        x2="369.599"
        y2="184.944"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7C7C7C" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_503_320"
        x1="314.485"
        y1="124.139"
        x2="335.798"
        y2="143.061"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CDA94C" />
        <stop offset="1" stopColor="#C0AF69" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_503_320"
        x1="524.907"
        y1="206.299"
        x2="525.256"
        y2="177.801"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CDA94C" />
        <stop offset="1" stopColor="#C0AF69" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_503_320"
        x1="95.0782"
        y1="204.732"
        x2="121.23"
        y2="193.405"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CDA94C" />
        <stop offset="1" stopColor="#C0AF69" />
      </linearGradient>
      <clipPath id="clip0_503_320">
        <rect width="606" height="225" fill="white" transform="translate(0 21)" />
      </clipPath>
      <clipPath id="clip1_503_320">
        <rect width="141" height="91" fill="white" transform="translate(351 162)" />
      </clipPath>
      <clipPath id="clip2_503_320">
        <rect width="53" height="98" fill="white" transform="translate(164 113)" />
      </clipPath>
      <clipPath id="clip3_503_320">
        <rect width="113.972" height="58" fill="white" transform="translate(166 149.088) rotate(-0.0440958)" />
      </clipPath>
    </defs>
  </svg>
)

export default Desert
